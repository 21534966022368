import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // 注意这里使用路由path:'/'Index, 如果你在app.vue  已经加载了首页，要删除app.vue中的组件，不然会重复加载  
  {
    path: '/',
    component: () => import('../views/index')
  },
  {
    path: '/broadcast',
    component: () => import('../views/broadcast')
  },
  {
    path: '/loading',
    component: () => import('../views/loading')
  }
]
const routers = createRouter({
  history: createWebHistory(),
  routes: routes
})
export default routers
