<template>
  <router-view />
</template>

<script>
window.addEventListener("beforeunload", () => {
  window.scroll(0, 0);
});
// import index from "./components/index.vue";
export default {
  name: "App",
  // components: {
  //   index,
  // },
};
</script>

<style lang="less">
/* 全局 */
#app,
body,
html {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
img,
video {
  display: block;
}
.flex{
  display: flex;
}
.flex-aic {
	align-items: center;
}
a {
  color: #333;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #000;
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
}

a,
button,
input,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  outline: none !important;
  -webkit-appearance: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

::-webkit-scrollbar {
  width: 14px;
  height: 1px;
}
::-webkit-scrollbar-thumb {
  //滑块部分
  border-radius:10px;
  background: #1EA78D;
}
::-webkit-scrollbar-track {
  //轨道部分
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
  border-radius: 5px;
}

/* 弹窗 */
.popup-conetnt {
  padding: 22px 0 49px;
  // height: 500px;
  border-radius: 20px;

  .popup-close {
    width: 20px;
    height: 20px;
    margin: 0 24px 0 auto;
  }

  .popup-tip {
    margin: -20px 0 42px;
    font-size: 24px;
    font-family: PingFang;
    font-weight: bold;
    color: #212a40;
    text-align: center;
  }

  .share-qrcode {
    width: 204px;
    height: 195px;
    margin: 0 auto;
  }

  .share-link {
    margin: 35px 33px 0;
    height: 58px;
    border: 1px solid;
    border-image: linear-gradient(0deg, #00fff6, #3886fd) 10 10;
    border-radius: 5px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 56px;

    p {
      padding: 0 20px;
    }

    span {
      display: inline-block;
      width: 83px;
      height: 58px;
      background: #1ea78d;
      border-radius: 0px 4px 4px 0px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }
  }

  .concat-call {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    text-align: center;

    span {
      color: #008acd;
    }
  }
}
</style>
